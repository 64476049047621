.releaseTreeTitle {
    margin-top: 2em;
}

.releaseTree {
    margin-top: 1em;
    margin-bottom: 1em;
}

.releaseNotesDesc {
    margin-top: 3em;
    max-width: 90%;
    text-align: justify;
    border-left: 2px solid rgba(110, 2, 2, 0.613);
    padding-left: 0.6em;
}

.releaseNotesImg {
    max-width: 100%;
    max-height: 80vh;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 5em;
}