.navigateLink {
  color: #000;
}
.navigateLink:hover {
  color: #000;
  text-decoration: underline;
}

/* .filter-button{
	float: right;
	margin-bottom: 15px;
} */

.pl5 {
  padding-left: 5px;
}

.savedSearch-link {
  color: maroon;
}

.savedSearch-link:hover {
  color: maroon;
  text-decoration: underline;
}

.stepsImages li:empty {
  display: none;
}

.stepsImages li {
  list-style-type: none;
  display: inline-flex;
}
.stepsImages li img {
  width: 32px;
  height: 32px;
}

.savedSearch-card {
  margin: 20px 0;
}

.savedSearch-card .ant-card-head {
  background-color: #5d1725;
  color: #fff;
}

.savedSearch-card .ant-card-body {
  height: 300px;
  overflow-y: auto;
  scrollbar-color: #5d1625 #fff;
}

.savedSearch-card .ant-card-body:hover {
  cursor: pointer;
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
