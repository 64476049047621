.practice-list-wrapper {
  height: 100%;
  width: 100%;
}

.practice-list {
  position: relative;
  height: 85vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.practice-best-match,
.practice-good-match,
.practice-fair-match {
  font-size: 16px;
  font-weight: normal;
}

.practice-best-match {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: normal;
  color: limegreen;
}

.practice-good-match {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: normal;
  color: yellow;
}

.practice-fair-match {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: normal;
  color: orange;
}

.practice-card-vertical {
  height: 550px;
  width: 340px;
  margin: 2vh -2vw;
  border-color: maroon;
}

.practice-card-vertical .scroll-indicator {
  display: none;
}

.practice-title {
  height: 40px;
  width: 20vw - 10px;
  margin-top: -10px;
  word-break: break-word;
  white-space: normal;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  display: none;
}

.practice-list::-webkit-scrollbar {
  width: 1em;
  display: none;
}

.practice-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(100, 7, 7, 0.3);
  display: none;
}

.practice-list::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(100, 7, 7, 0.3);
  display: none;
}

.filter-wrapper {
  position: relative;
  overflow-y: scroll;
  height: 80vh;
  width: 32vw;
  background-color: lightgray;
}

.report-button {
  position: absolute;
  width: 100%;
  margin-bottom: 0.6em;
  bottom: 0;
  color: white !important;
  background-color: #42111d !important;
  z-index: 999 !important;
}

.posrel {
  position: sticky;
  margin-top: 1em;
}

.filter-wizard-form {
  width: 25vw;
  height: 20vw;
}

.filter-map {
  left: 0vw;
}

.filter-container {
  display: flex;
  margin: 2em;
  border: 3px solid maroon;
  border-radius: 10px;
  align-items: center;
}

.filter-buttons-container {
  /* left: 50px;
  margin: 10px; */
  width: 15%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.filter-button {
  color: maroon !important;
  background-color: white !important;
  border: solid gray 1px;
  border-radius: 5px;
  min-width: 115px;
}

.filter-results {
  color: black;
  margin-left: 2em;
  width: 75%;
}

.filter-tag {
  color: black;
  padding: 1px 5px;
  margin: 5px;
  display: inline-block;
  border: 2px solid black;
  border-radius: 5px;
}

.filter-options-container {
  display: flex;
  width: 30%;
  gap: 10px;
  flex-wrap: wrap;
}

.page-view-container {
  align-self: flex-start;
}

.list-view-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  margin: 5px;
  color: maroon;
  border: solid gray 1px;
  border-radius: 5px;
  cursor: pointer;
}

.list-view-button:hover {
  border-color: maroon;
  color: maroon !important;
}

.list-view-button-div {
  display: flex;
  width: 95px;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
}

.ant-card-head-title {
  text-transform: uppercase;
}

.ant-card-body {
  padding: 10px 20px !important;
}

.practice-card-horizontal {
  height: 150px;
  width: 100%;
  margin: 0;
  border-color: maroon;
}

.practice-card-horizontal .ant-card-body {
  height: 105px;
  gap: 20px;
}

.practice-card-horizontal .ant-card-head {
  height: 50px;
}

.practice-card-horizontal .practice-number,
.practice-card-horizontal .practice-title {
  display: inline;
}

.practice-card-horizontal .step2_images,
.practice-card-horizontal .step3_images {
  width: 20%;
  margin: 0;
}

.practice-card-horizontal .img_selected_cb {
  width: 40px;
  height: 40px;
}

.practice-card-horizontal .signup-icons {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top !important;
}

.practice-card-horizontal .incentive-icons {
  display: inline-block;
  max-width: 150px;
  max-height: 100px;
  vertical-align: top !important;
}

.step2_images {
  max-height: 100px;
  overflow-y: auto;
}

/* .step2_images:not(.step2_images:has(img)) {
  display: none;
} */

.step3_images {
  max-height: 150px;
  overflow-y: auto;
  min-width: 110px;
}

/* .step3_images:not(.step3_images:has(img)) {
  display: none;
} */

.img_selected_cb {
  margin: 0.2em;
  width: 50px;
  height: 50px;
  border: 1px solid maroon;
  border-radius: 10%;
  padding: 0.2em;
  vertical-align: bottom;
  /* -webkit-filter: invert(100%);
  filter: invert(100%); */
}

.signup-icons {
  vertical-align: middle;
  align-items: center;
}

.incentive-icons {
  vertical-align: middle;
  align-items: center;
}

.incentive-percent {
  font-size: 1.1em;
  line-height: 1.5em;
  vertical-align: top;
  align-items: center;
}

.practice-select-button {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 80px;
  height: 80px;
  color: maroon;
  background-color: lightgray;
  border: 1px dashed maroon;
}

.practice-select-button-selected {
  position: absolute;
  top: 80px;
  right: 20px;
  width: 80px;
  height: 80px;
  padding: 0px;
  color: maroon;
  background-color: #fffdd0;
  border: 1px solid black;
}

.detail-left {
  position: absolute;
  left: 20px;
  bottom: 0px;
}

.detail-right {
  position: absolute;
  right: 20px;
  bottom: 5px;
}

.report-practice-detail-container .detail-right {
  bottom: -10px;
}

.detail-button {
  display: flex;
  color: #42111d;
  vertical-align: bottom;
  align-items: center;
}

.detail-button:hover {
  color: maroon;
}

.most-common {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 110px; /* the top offset */
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #307d00;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

.practice-card.pc-grid-view {
  margin: 15px 0;
  height: 600px;
}

.filter-practice-row {
  flex-wrap: wrap;
  gap: 2%;
  justify-content: space-evenly;
}

@media (max-width: 1100px) {
  .filter-practice-row {
    justify-content: space-around;
  }

  .filter-practice-row > .ant-col {
    width: 350px;
    max-width: none;
  }

  .filter-container {
    justify-content: space-around;
    margin: 0;
  }

  /* .filter-button {
    width: 60%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 18px;
  } */
  .filter-results {
    display: flex;
    flex-direction: column;
  }

  .filter-results h3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter-tag {
    border-radius: 20px;
    background-color: rgb(111, 111, 111);
    color: white;
    padding: 1px 12px 3px;
    font-weight: 500;
  }

  .practice-best-match,
  .practice-good-match,
  .practice-fair-match {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .ant-dropdown-trigger.list-view-button {
    display: none;
  }
  .ant-modal-content {
    padding: 4px !important;
    min-height: 40vh;
  }

  .filter-map {
    display: none;
  }

  .filter-container {
    justify-content: space-around;
    padding: 10px;
    flex-direction: column;
  }

  .filter-practice-row {
    flex-direction: column;
    max-width: 100vw;
  }

  .practice-card-vertical {
    width: 80vw;
    margin: 5px 0;
  }

  .filter-practice-row > .ant-col {
    max-width: none;
  }

  .filter-results {
    display: flex;
    flex-direction: column;
  }

  .filter-results h3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter-tag {
    border-radius: 20px;
    background-color: rgb(111, 111, 111);
    color: white;
    padding: 1px 12px 3px;
    font-weight: 500;
  }

  .filter-modal {
    width: 90vw !important;
    max-height: 90vh;
    align-self: center;
    justify-self: center;
  }

  /* .filter-modal .ant-modal-content .ant-modal-body .ant-row .ant-col,
  .filter-modal .ant-modal-content .ant-modal-body .ant-row {
    max-width: 100%;
    width: 100%;
    flex: unset;
  } */

  .filter-modal .ant-modal-content .ant-modal-footer {
    height: 50px;
    display: flex;
    justify-content: space-around;
  }

  .ant-modal-body {
    max-height: 84vh;
    overflow: scroll;
  }

  .page-view-container {
    display: none;
  }

  .filter-buttons-container,
  .filter-results,
  .filter-options-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .filter-button {
    width: 60%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    font-size: 18px;
    min-width: 125px;
  }
}

.ant-modal-body {
  max-height: 70vh;
  overflow: scroll;
}

.ant-modal-content {
  max-height: 90vh;
  padding: 20px 10px !important;
}

.filter-modal .ant-modal-content .ant-modal-footer {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  margin: auto;
}

.filter-practice-row.list-view {
  flex-direction: column;
  max-width: 100vw;
}

.practice-card.list-view {
  width: 80vw;
  margin: 5px 0;
  height: auto;
}

.filter-practice-row.list-view > .ant-col {
  max-width: none;
  margin: 0;
}

.practice-card.list-view .ant-card-body {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 3px 0;
  height: 105px;
}

.practice-card.list-view .ant-card-head {
  min-height: unset;
  height: auto;
}

.practice-card.list-view .ant-card-body > p {
  margin: 0;
  padding: 0;
}

.practice-card.list-view
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-head-title
  p {
  height: auto;
  min-height: unset;
  margin: 2px 0;
}

.practice-card.list-view .ant-card-body .step2_images {
  width: 20%;
}

.practice-card.list-view .ant-card-body .step3_images {
  width: 54%;
}

.practice-card.list-view .ant-card-body .s-i-container {
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  gap: 10px;
}

.practice-card.list-view .ant-card-body .s-i-container .incentive-icons,
.practice-card.list-view .ant-card-body .s-i-container .signup-icons {
  margin: 0;
}

.practice-card.list-view .ant-card-body .s-i-container .incentive-icons {
  width: 100%;
  min-width: 150px;
}

.practice-card.list-view .ant-card-body .most-common {
  --t: 30px;
}

.practice-card.list-view {
  max-width: 1120px;
  min-width: 1040px;
}

.incentive-icons-inner {
  display: flex;
  flex-wrap: wrap;
}

button.practice-card-container .incentive-icons-inner {
  justify-content: center;
}

.incentive-percent {
  width: 75px;
  font-size: 14px;
}

.incentive-percent span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ant-switch .ant-switch-inner .ant-switch-inner-unchecked,
.ant-switch .ant-switch-inner .ant-switch-inner-checked {
  font-size: 20px;
}

.toOverrideAntD {
  display: flex;
  justify-content: center;
  width: 350px;
  max-width: none;
  flex-basis: initial;
}

.ant-card .ant-card-body {
  padding: 10px;
}

.text-white .anticon-heart {
  color: #807373;
}

.text-white .ant-rate-star-full .anticon-heart {
  color: #fff;
}

.ant-design--heart-filled {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23000' d='M923 283.6a260 260 0 0 0-56.9-82.8a264.4 264.4 0 0 0-84-55.5A265.3 265.3 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39q-15 9.15-28.5 20.1q-13.5-10.95-28.5-20.1c-41.8-25.5-89.9-39-139.2-39c-35.5 0-69.9 6.8-102.4 20.3c-31.4 13-59.7 31.7-84 55.5a258.4 258.4 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9c0 33.3 6.8 68 20.3 103.3c11.3 29.5 27.5 60.1 48.2 91c32.8 48.9 77.9 99.9 133.9 151.6c92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3c56-51.7 101.1-102.7 133.9-151.6c20.7-30.9 37-61.5 48.2-91c13.5-35.3 20.3-70 20.3-103.3c.1-35.3-7-69.6-20.9-101.9'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  color: #8f8a8b;
}

.ms-heart-filled {
  color: #f3eaea;
}

.userSaveSearch_key {
  margin-left: 172px;
  max-width: 150px;
  display: inline-block;
  position: absolute;
  /* background: red; */
  margin-top: -30px;
}

.scroll-indicator {
  display: none;
}

.report-practice-detail-container .scroll-indicator {
  display: block;
  text-align: center;
  position: absolute;
  z-index: 9;
  bottom: -3px;
  left: 38%;
}

@media (max-width: 1100px) {
  .filter-practice-row {
    justify-content: space-around;
  }

  .filter-container {
    justify-content: space-around;
    padding: 0 10px;
  }

  .filter-results {
    display: flex;
    flex-direction: column;
  }

  .filter-results h3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter-tag {
    border-radius: 20px;
    background-color: rgb(111, 111, 111);
    color: white;
    padding: 1px 12px 3px;
    font-weight: 500;
  }

  .practice-best-match,
  .practice-good-match,
  .practice-fair-match {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .detail-right {
    bottom: -10px;
  }

  .ant-list-item > div {
    width: 100%;
  }

  .practice-card-horizontal.ant-card .ant-card-body {
    display: flex;
    width: 100%;
    padding: 0 !important;
    gap: 3px;
  }

  .practice-card-horizontal.ant-card .ant-card-body .step2_images,
  .practice-card-horizontal.ant-card .ant-card-body .step3_images,
  .practice-card-horizontal.ant-card .ant-card-body .signup-icons,
  .practice-card-horizontal.ant-card .ant-card-body .incentive-icons {
    width: 22%;
    margin-right: 0;
  }

  .incentive-icons-inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .incentive-icons-inner span {
    display: flex;
    width: 50%;
    align-items: center;
  }
  .incentive-percent {
    width: 40px;
  }

  .practice-card-horizontal.ant-card .ant-card-body .incentive-icons {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  .practice-card-horizontal.ant-card .ant-card-body .signup-icons {
    max-width: 18%;
    margin-left: 5px;
  }

  .ant-modal-content {
    padding: 4px !important;
    min-height: 40vh;
  }

  .practice-best-match,
  .practice-good-match,
  .practice-fair-match {
    font-size: 16px;
    font-weight: normal;
  }

  .filter-map {
    display: none;
  }

  .filter-container {
    justify-content: space-around;
    padding: 0 10px;
    flex-direction: column;
  }

  .filter-button {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
    font-size: 18px;
  }

  .filter-practice-row {
    flex-direction: column;
    max-width: 100vw;
  }

  .practice-card-horizontal {
    width: 80vw;
    margin: 5px 0;
  }

  .filter-practice-row > .ant-col {
    max-width: none;
  }

  .filter-results {
    display: flex;
    flex-direction: column;
  }

  .filter-results h3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter-tag {
    border-radius: 20px;
    background-color: rgb(111, 111, 111);
    color: white;
    padding: 1px 12px 3px;
    font-weight: 500;
  }

  .filter-modal {
    width: 90vw !important;
    max-height: 90vh;
    align-self: center;
    justify-self: center;
  }

  /* .filter-modal .ant-modal-content .ant-modal-body .ant-row .ant-col,
  .filter-modal .ant-modal-content .ant-modal-body .ant-row {
    max-width: 100%;
    width: 100%;
    flex: unset;
  } */

  .filter-modal .ant-modal-content .ant-modal-footer {
    height: 50px;
    display: flex;
    justify-content: space-around;
  }

  .ant-modal-body {
    max-height: 84vh;
    overflow: scroll;
  }

  .page-view-container {
    display: none;
  }
}
