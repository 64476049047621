.report-head-btn-container.print {
  display: none;
}

.report-map-inner-container.print {
  width: 600px;
  height: 400px;
  margin: auto;
}

.print.step2_images ul,
.print.step3_images ul {
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 0;
  padding-left: 3px;
}

.print.step2_images ul li,
.print.step3_images ul li {
  margin: 0 10px;
}

.print.step2_images,
.print.step3_images {
  max-width: none;
  width: auto;
}

.print.step2_images {
  margin-bottom: 20px;
}

.print.practice-card-horizontal {
  height: auto;
  border: none;
}

.print.ant-card .ant-card-body {
  display: flex;
  flex-direction: column;
}

.print.report-practice-list-short {
  width: 100%;
  padding: 0;
  border: none;
}

.print.report-practice-detail {
  padding: 0 20px 20px 20px;
}

.print.report-practice-detail-container {
  page-break-inside: avoid;
  border: solid maroon 2px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.ant-card .ant-card-body:has(.print) {
  height: auto;
  gap: 0;
}

.print.report {
  width: 700px;
  min-width: initial;
}
