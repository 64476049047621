.report {
  width: 80vw;
}

.report-header {
  position: relative;
  left: 10vw;
  color: maroon;
}

.report-hr {
  position: relative;
  left: 10vw;
}

.report-print-button {
  position: absolute;
  top: 20vh;
  right: 10vw;
  color: white !important;
  background-color: #42111d !important;
}

.report-section-header {
  position: relative;
  left: 10vw;
  color: maroon;
}

.report-basic-information {
  position: relative;
  left: 10vw;
}

.report-practice-list {
  position: relative;
  left: 10vw;
  height: 100%;
  width: 80vw;
  overflow-y: none;
}

.report-usda-information {
  position: relative;
  left: 10vw;
}