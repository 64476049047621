.msu_footer {
  background: #5d1725;
  padding-top: 20px;
}

.footer-row {
  display: flex;
  justify-content: space-between;
}

.footer-link {
  margin: 0vh 9vw;
  font-size: 0.8rem;
  color: white;
}

.fs-16 {
  font-size: 16px !important;
}

.footer_image {
  width: 172px !important;
}

.ml-2 {
  margin-left: 10px;
}

.footer_left_nav li {
  float: left;
  list-style-type: none;
  margin-right: 0.4em;
  padding: 2px;
}

.footer_left_nav {
  padding: 0;
  font-size: 1px;
}

.footer_left_nav li a {
  line-height: 1em;
  padding-right: 0.6em;
  border-right: 1px solid #dad4d4;
  font-size: 11px;
}

.footer_left_nav li:first-child a {
  padding-right: none;
}

.footer_left_nav li:last-child a {
  border-right: none;
}

p.text-white-shade {
  margin: 2px 0;
}

.f11 {
  font-size: 11px;
}

.link-button {
  background-color: transparent;
  border: none;
  color: white;
}

.link-button:hover {
  cursor: pointer;
}

@media (max-width: 1100px) {
}

@media (max-width: 700px) {
  .footer_left_nav {
    text-align: center;
  }

  .footer_left_nav li a {
    font-size: 11px;
  }

  .footer_image {
    margin-left: 33%;
  }

  p.text-white-shade {
    text-align: center;
    padding-top: 10px;
    margin: 0;
    clear: both;
  }
}
