.practice-card-fav {
    min-height: 300px;
    width: 20vw;
    margin: 2vh -2vw;
    border-color: maroon;
}

.filter-practice-row-fav{
    margin-top:1em;
}

@media (max-width: 600px) {

    .practice-card-fav{
        width: 80vw;
        margin: 5px 0;
    }

}

