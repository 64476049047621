.about_img_resize {
  max-height: 80%;
}

.email-address {
  color: black !important;
}

.teamimg {
  width: 150px;
}

@media (max-width: 1100px) {
}

@media (max-width: 700px) {
  .teamimg .ant-image-img {
    width: 80px;
    margin-top: 30px;
  }

  .teamimglg {
    width: 90%;
  }
}
