.userProfileData {
  max-width: 100%;
  margin: 0;
}

.userProfileData td {
  width: 200px;
  height: initial;
}

.detailsUpdateLink {
  color: #000;
}
.ant-row .ant-col:has(.ant-card-body table) {
  width: 100%;
  margin-inline-start: initial;
  max-width: none;
  flex: initial;
}

div.ant-tabs:has(.ant-col) {
  align-items: unset !important;
}

@media (max-width: 700px) {
  .ant-tabs-content .ant-row .ant-col {
    max-width: none;
    width: 100%;
    margin-inline-start: initial;
    flex: initial;
  }

  /* Uncomment if you want the tab pane to be overflow after a certain height*/

  /* .ant-tabs-content .ant-tabs-tabpane-active {
    max-height: 80vh;
    overflow-y: auto;
  } */
}
