.workshop-container {
  border: 2px solid maroon;
  border-radius: 10px;
}

.workshop-header {
  color: maroon;
}

.event-header {
  color: maroon !important;
}

.workshop-registration-button {
  bottom: 0;
  left: 4vw;
  background-color: #42111d;
  border-color: #fff;
  color: #fff;
  padding: 0 1em 1em 1em;
  border-radius: 10em;
  min-width: 2em;
  min-height: 2em;
  border-width: 3px;
  font-size: 0.8rem;
}

.workshop-photo-container {
  height: 25vw;
  width: 100%;
}

.workshop-photo {
  border-radius: 10px;
  z-index: 1;
}

.workshop-photo-label {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
  z-index: 10;
}

.usa-state.ia:hover,
.usa-state.il:hover,
.usa-state.in:hover,
.usa-state.mo:hover,
.usa-state.ms:hover,
.usa-state.oh:hover {
  stroke-width: 3px;
}

.react-card-flip {
  height: 280px !important;
  width: 280px !important;
  margin: 20px !important;
  border: 1px solid black;
  border-radius: 10px;
}

.vitural-workshop-button {
  position: absolute;
  right: 0px;
  bottom: 10px;
  height: 10vw !important;
  width: 10vw !important;
}

.event-image-square {
  height: 150px !important;
  width: 150px !important;
  margin: 0px 20px !important;
  cursor: pointer;
}

.event-image-rectangle {
  height: 120px !important;
  width: 150px !important;
  margin: 0px 20px !important;
  cursor: pointer;
}

.workshop-image {
  height: 250px !important;
  width: 250px !important;
  margin: 0px 15px !important;
  cursor: pointer;
}

.workshop-label {
  display: flex;
  justify-content: center;
}

.workshop-archive {
  height: 200px !important;
  width: 250px !important;
  padding: 20px 5px !important;
  overflow: auto;
}

.workshop-archive-labels {
  margin: 0.8em;
}

.workshop-summary-button {
  color: white !important;
  background-color: maroon !important;
}

.workshop-summary {
  height: 15vw;
  width: 10vw;
  margin-left: 1.5vw;
  cursor: pointer;
}

.workshop-summary canvas {
  width: 100%;
  max-width: 12vw;
  height: 100%;
  max-height: 15vw;
}

.mapStyle {
  width: "30vw";
  height: "80vh";
  margin: "2vh";
}

@media (max-width:576px) {
  .workshop-image {
    width: 100%;
    height: auto;
    margin-bottom: 1em;
  }
}
