.report-head-container {
  display: flex;
  justify-content: space-between;
}

.report-head-btn-container {
  display: flex;
  align-items: center;
}

.report .map-practice-container {
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: initial !important;
  vertical-align: initial !important;
}

.report-map {
  width: 100%;
  height: 400px;
}

.map-practice-container .report-practice-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: initial !important;
  vertical-align: initial !important;
}

.map-rental-container {
  width: 50%;
}

.report-map-container {
  position: initial;
  left: initial;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.report-map-inner-container {
  width: 100%;
  height: 400px;
}

.report-layer-select {
  float: right;
  height: 30px;
  padding: 0;
  width: 30px;
  margin: 10px 10px 0 0;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.practice-info-container {
  display: none;
}

.report-practice-container .report-practice-list {
  display: flex;
  flex-direction: column;
  position: initial;
  height: auto;
  width: 100%;
  align-items: initial !important;
  vertical-align: initial !important;
}

.report-practice-container .report-practicecard-container {
  width: 100%;
}

.report-usda-information,
.report-disclaimer,
.report-hr,
.report-rental-table-container .report-table-information,
.report-basic-information {
  position: initial;
}

.report-practice-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 550px;
}
button.practice-card-container .ant-card:hover {
  cursor: pointer;
}

button.practice-card-container .ant-card .ant-card-body {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

button.practice-card-container .ant-card .ant-card-head {
  padding: 0 5px;
  text-align: left;
}

button.practice-card-container .ant-card .ant-card-body .step2_images,
button.practice-card-container .ant-card .ant-card-body .step3_images,
button.practice-card-container .ant-card .ant-card-body .signup-icons,
button.practice-card-container .ant-card .ant-card-body .incentive-icons {
  width: 25%;
  flex-wrap: wrap;
  min-width: 83px;
}

button.practice-card-container .ant-card .ant-card-body .step2_images {
  min-width: 110px;
}

button.practice-card-container .ant-card .ant-card-body .step3_images {
  max-height: 75px;
  overflow-x: scroll;
  min-width: 110px;
  width: 33%;
}

button.practice-card-container
  .ant-card
  .ant-card-body
  .incentive-icons
  .incentive-percent {
  display: none;
}

.incentive-icons {
  min-width: 120px !important;
}

.practice-select-button {
  display: none;
}

.practice-card-container {
  overflow: hidden;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0;
}

.practice-card-container.selected-practice .ant-card .ant-card-body {
  background-color: maroon;
}

.practice-card-container .ant-card .ant-card-body {
  padding: 0;
}

.selected {
  background-color: rgb(214, 214, 214) !important;
}

.most-common-compact {
  --f: 10px;
  --r: 15px;
  --t: 50px;
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #307d00;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

/***************** OLD STYLES ******************/

.report {
  width: 80vw;
  margin: auto;
  min-width: 1100px;
}

.report-header {
  margin-bottom: 0;
}

.report-header,
.report-section-header {
  position: initial;
  color: maroon;
}

.report-button {
  position: initial;
  color: white !important;
  background-color: #42111d !important;
}

.report-button-icon {
  vertical-align: middle;
  margin: 0px 5px 0px 0px;
}

.report-avatar-group {
  position: relative;
  left: 10vw;
  margin: 1vh 0vw;
}

.report-avatar {
  color: maroon;
  font-weight: 500;
}

/* .report-practice-list {
  position: relative;
  left: 6vw;
  height: 100%;
  width: 100%;
  overflow: hidden;
} */

ul.slick-dots > li {
  background-color: maroon;
  border: 1px maroon solid;
}

.practice-card-horizontal {
  height: 160px;
  width: 100%;
  margin: 0;
  border-color: maroon;
}

.report-map-container.print,
.report-header.print,
.report-hr.print,
.report-section-header.print,
.report-table.print,
.report-basic-information.print,
.report-practice-list.print,
.report-practice-list-short.print,
.report-usda-information.print,
.report-disclaimer.print {
  left: 0;
}

.report-layer-options {
  position: absolute;
  top: 10px;
  right: 40px;
  width: 200px;
  height: 300px;
  padding: 0px 10px;
  background-color: lightgray !important;
  border: 1px solid black;
  border-radius: 10px;
}

.report-layer-switch {
  width: 80px;
  height: 22px;
  margin: 10px;
}

.report-layer-switch .ant-switch-inner-checked {
  font-size: 12px !important;
}

.report-layer-switch .ant-switch-inner-unchecked {
  font-size: 12px !important;
}

.report-legend-soil {
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 12px;
  margin: 2px 0px;
  background-color: rgba(128, 0, 128, 0.5);
  border: 2px solid white;
}

.report-legend-safe {
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 12px;
  margin: 2px 0px;
  background-color: rgba(255, 165, 0, 0.5);
  border: 2px solid rgba(255, 165, 0, 0.5);
}

.report-legend-floodplain {
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 12px;
  margin: 2px 0px;
  background-color: rgba(0, 0, 255, 0.5);
  border: 2px solid rgba(0, 0, 255, 0.5);
}

.report-legend-water {
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 12px;
  margin: 2px 0px;
  background-color: rgba(0, 255, 255, 0.5);
  border: 2px solid rgba(0, 255, 255, 0.5);
}

.report-practice-list-short {
  height: 100%;
  width: 90%;
  padding: 0 20px 10px 20px;
  align-self: center;
  border: solid 1px maroon;
  border-radius: 0 0 10px 10px;
  border-top: none;
}

.report-table-highlight {
  background-color: rgb(160, 32, 240, 0.3) !important;
}

.report-disclaimer {
  font-size: 0.8em;
}

@media print {
  .msu_header,
  .msu_footer,
  .report-print-button,
  .report-layer-select,
  .report-layer-options,
  .report-usda-button {
    display: none;
  }

  .ant-card.practice-card.practice-card-horizontal {
    page-break-inside: avoid;
  }

  @page {
    size: A4;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (max-width: 700px) {
}
