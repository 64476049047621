.sliderimg {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 75%,
      rgba(255, 255, 255, 0.05) 100%
    ),
    url("../../images/homepage/tractor_msstate.png");
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}

.crpheader {
  padding-top: 6rem;
  font-weight: bold;
  font-size: 5rem;
  text-align: center;
}

.crpslidertext p {
  font-size: 2rem;
  margin-top: 5vh;
}

.crpslidertext h6 {
  font-weight: bold;
}

.crpslidertext {
  font-weight: bold;
  text-align: center;
}

.crpslidertext .subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.homepage-card {
  margin: 5vh 1vw 0vh;
}

.homepage-card-icon {
  height: 100px;
  width: 100px;
  margin: 20px 10px -20px 0px;
  float: left;
}

.homepage-card-content {
  text-align: justify;
}

.homepage-card-button {
  margin-top: 5vh;
}

@media (max-width: 1100px) {
}

@media (max-width: 700px) {
  .sliderimg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .crpheader {
    padding-top: 0;
    font-size: 3rem;
  }

  .crpslidertext .subtitle {
    margin-top: 3em;
    font-size: 1rem;
  }

  .ant-card-body {
    margin: 10px 0;
  }

  .ant-card-body,
  .homepage-card {
    padding: 0 !important;
  }

  .homepage-card-button {
    margin-bottom: 10px;
  }

  .filter-practice-row .ant-col .ant-card .ant-card-body {
    padding: 0 24px !important;
  }
}
