/* *{
  font-size:1rem;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img {
  max-width: 100%;
}

textarea {
  resize: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.big-maroon-btn:hover,
.big-maroon-btn:first-child:active,
.big-maroon-btn {
  background-color: #42111d;
  border-color: #fff;
  padding: 0.6em;
  border-radius: 5em;
  min-width: 12em;
  border-width: 3px;
  font-size: 1.2rem;
  font-weight: 500;
}

.small-maroon-btn:hover,
.small-maroon-btn:first-child:active,
.small-maroon-btn {
  background-color: #42111d;
  border-color: #fff;
  padding: 0.4em;
  border-radius: 5em;
  min-width: 8em;
  border-width: 2px;
  font-size: 0.8rem;
}

.big-maroon-btn:hover,
.big-maroon-btn:first-child:active,
.small-maroon-btn:hover,
.small-maroon-btn:first-child:active {
  background-color: #5f2a37;
}

.pt-5 {
  padding-top: 50px;
}

.mt-3 {
  margin-top: 30px;
}

.text-white-shade {
  color: #dad4d4;
  font-size: 10px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-center {
  justify-content: center;
  width: 100%;
}

.text-justify {
  text-align: justify;
}

.text-bold {
  font-weight: bold;
}

ul,
li {
  line-height: 2em;
}

.empty {
  margin-top: 5em;
}

.dropdown-item.active,
.dropdown-item:active {
  border: 1px solid rgb(33, 37, 41);
  color: rgb(33, 37, 41);
  background-color: rgb(248, 249, 250);
}

.p-0 {
  padding: 0;
}

.pa {
  /* position: absolute; */
}

.mt-1 {
  margin-top: 10px;
}

.textBlack {
  color: #000;
}

@media (max-width: 1100px) {
}

@media (max-width: 700px) {
  /* common classes */
  .mt-3 {
    margin-top: 1rem;
  }

  .pt-5 {
    padding-top: 2rem;
  }

  .empty {
    margin-top: 1em;
  }
  /* common classes */

  .text-align-right {
    text-align: justify;
  }
  .img80 {
    max-width: 80%;
  }
}
