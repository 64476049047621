.checkbox-group {
  width: 100%;
}

.checkbox-container {
  height: 140px;
  width: 140px;
  margin: 0.5em;
  padding: 2px;
  padding-right: 0;
  border: 2px solid #000;
  border-radius: 10px;
  align-items: center;
}

.checkbox-container:hover {
  border: 2px solid maroon;
}

.checkbox-wrapper {
  display: inline-grid;
}

.ant-checkbox-wrapper-checked {
  color: white;
  background-color: #5d1725;
}

.ant-checkbox-wrapper-checked > span > .checkbox-image {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.checkbox-form {
  margin-left: -2vw;
}

.checkbox-image {
  height: 80px;
  width: 80px;
  margin-left: 20px;
}

.checkbox-label {
  display: flex;
  width: 160px;
  margin-left: -20px;
  font-size: 15px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
}

.checkbox-list .ant-checkbox-wrapper:first-child {
  margin-left: 0;
}

.checkbox-list .ant-checkbox-inner {
  display: none;
}

.checkbox-list .ant-checkbox-inner:after {
  margin-left: 0px;
}

.checkbox-list .ant-checkbox:hover .ant-checkbox-inner {
  border-color: maroon;
}

.checkbox-list .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: maroon;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: maroon;
  border-color: maroon;
}

.pa {
  margin-left: -5px;
}

.ant-btn-primary {
  color: white !important;
  background-color: maroon !important;
  line-height: normal;
}

.ant-steps {
  width: 45vw !important;
}

.ant-steps-item-container {
  width: 10vw !important;
}

.ant-steps-item-title {
  font-size: 0.8rem !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: maroon !important;
  opacity: 50% !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: white !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: maroon !important;
  width: 5vw !important;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: maroon !important;
  border-color: maroon !important;
}

.ant-steps-item-active
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  width: 5vw !important;
}

.draggable-tag {
  margin: 5px;
}

.typeHeading {
  flex-basis: 100%;
  margin: 0;
  font-size: large;
  padding: 1em;
}

.ant-checkbox-group div.ant-col {
  padding-left: 0 !important;
}

.tag-container {
  height: 140px;
  width: 140px;
  margin: 0.5em;
  padding: 2px;
  padding-right: 0;
  border: 2px solid #000;
  border-radius: 10px;
  align-items: center;
}

.tag-container:hover {
  cursor: grab;
}

.tag-sequence {
  position: absolute;
  color: maroon;
  font-size: 20px;
  padding: 1px 10px;
  border: 2px solid maroon;
  border-radius: 50%;
}

.tag-image {
  height: 80px;
  width: 80px;
  margin-top: 20px;
  margin-left: 30px;
}

.tag-label {
  display: flex;
  width: 160px;
  margin-left: -10px;
  font-size: 15px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
}

.ranking-box {
  padding: 10px;
  margin: 20px;
  border: 4px solid maroon;
  border-radius: 10px;
}

.ranking-box-first {
  margin-left: 3vw !important;
}

.ranking-label {
  display: flex;
  color: maroon;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
}

.custom-checkbox {
  background-color: white !important;
  color: black;
}

.custom-checkbox b {
  color: maroon;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: maroon !important;
  border-color: rgb(165, 0, 0);
}

.custom-checkbox {
  font-size: 18px !important;
}

@media (max-width: 1100px) {
  .ant-steps-item-title {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .select-font-size {
    font-size: 16px !important;
  }

  .ranking-box {
    width: 43%;
    margin: 10px;
    padding: 0;
  }

  .wizard-steps {
    flex-direction: row !important;
  }
  .ant-steps-item-tail::after {
    transform: rotate(90deg) translateY(-25px) translateX(-22px) !important;
  }

  .checkbox-group {
    width: 100%;
    justify-content: center;
  }

  .checkbox-list {
    margin: 0 !important;
    width: 100%;
  }

  .checkbox-container,
  .tag-container {
    height: 100px;
    width: 110px;
    justify-content: center;
    line-height: 1 !important;
    position: relative;
  }

  .checkbox-image,
  .tag-image {
    height: 60px;
    width: 75px;
    margin-left: 0;
  }

  .tag-image {
    margin-top: 0;
    margin-left: 5px;
  }

  .checkbox-label,
  .tag-label {
    width: 100%;
    margin-left: 0;
    font-size: 15px;
    white-space: wrap;
    text-align: center;
  }

  .pa {
    /* position: absolute; */
    top: 10px;
    right: 3px;
  }

  .mt-1.priority-row {
    width: 110%;
    justify-content: center;
  }

  span:has(img.checkbox-image),
  span:has(img.tag-image) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
