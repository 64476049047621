.embed-responsive-item {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.practice-page {
  margin: 50px 200px;
}

.pa {
  margin-left: 0px;
  margin-top: -7px;
}

.details-print-button.report-print-button {
  position: absolute;
  top: 200px;
  right: 50px;
}

.details-print-button:hover {
  cursor: pointer;
}

@media (max-width: 1100px) {
  .practice-page {
    margin: 15px;
  }

  .pa-mobile {
    position: absolute;
    right: 34vw;
    top: 0;
  }

  .mobile-h4-position {
    position: relative;
  }

  .ant-popover-inner {
    position: relative;
    right: 52vw;
    width: 60vw !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

@media print {
  crpMobileNavIcon {
    display: none;
  }

  .header-nav {
    display: none;
  }

  .embed-responsive-item,
  .embed-responsive {
    display: none;
  }

  .ant-collapse {
    background: none;
  }

  .ant-collapse-item-active {
    page-break-inside: avoid;
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  /**** To hide print button on mobile ****/
  .details-print-button.report-print-button {
    display: none;
  }

  .practice-page {
    margin: 15px;
  }

  .embed-responsive-item {
    width: 100%;
    height: 60vh;
  }

  .pa-mobile {
    position: absolute;
    right: 30vw;
    left: initial;
    top: 0;
  }

  .mobile-h4-position {
    position: relative;
  }

  .ant-popover-inner {
    position: relative;

    right: 52vw;
    width: 60vw !important;
  }
}

.cost-maint-manage {
  margin: 50px auto 30px auto;
}

.col-table-data .ant-col {
  border: 1px solid grey;
  text-align: center;
  height: 85px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.table-responsive {
  overflow-x: auto;
  margin: 20px 0;
}

table {
  max-width: 100%;
  border-collapse: collapse;
  margin: auto;
}

th,
td {
  width: 180px;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ddd;
  font-weight: bold;
  padding: 10px;
  overflow: hidden;
}

td p {
  font-weight: normal;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.pdf-link {
  color: maroon;
}

.pdf-link {
  cursor: pointer;
  color: rgb(101, 0, 0);
}
