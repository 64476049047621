.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
