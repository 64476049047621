.about-paragraph {
  padding-right: 5vh;
  font-size: 1.1em;
}

.crpmenu_logos img {
  max-width: 200px;
  max-height: 100px;
}

div:has(.ant-col) {
  align-items: center !important;
  vertical-align: middle !important;
}

.ant-list-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 1100px) {
}

@media (max-width: 700px) {
  .about-paragraph {
    margin-top: 0;
    padding-right: 0;
  }
}
