.print-incentive-container,
.print-signup-container {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
}

.print-signup-container {
  justify-content: flex-start;
}

.print-incentive-percent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 210px;
}

.print-filter-results {
  color: black;
  margin-left: 2em;
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.print-ul {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;
  margin: 0;
  /* padding-top: 5px; */
}

.print-practice-container {
  width: 1000px;
  padding: 15px;
  background-color: aliceblue;
  margin: 15px auto;
  box-shadow: 0 3px 4px rgb(178, 194, 208);
  position: relative;
}

.print-signup {
  width: 29%;
  justify-content: flex-start;
}

.print-button {
  position: absolute;
  top: 170px;
  right: 20%;
}
.close-button {
  position: absolute;
  top: 30px;
  left: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: lightgray;
  width: 10vw;
  height: 5vh;
  box-shadow: 2px 2px 2px gray;
}

.close-button:hover {
  border-color: maroon !important;
  color: maroon !important;
}

.close-text {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.print-practice-title {
  max-width: 83%;
}

.print-most-common {
  --t: 70px;
}

.print-practice-match {
  position: absolute;
  top: 30px;
  right: 10px;
}

.print-practice-match .practice-best-match,
.print-practice-match .practice-good-match,
.print-practice-match .practice-fair-match {
  color: black;
  font-weight: bold;
  margin: 0;
  font-size: 22px;
}

/* one item */
.print-incentive-container:has(> :nth-child(1):last-child) {
  width: 25%;
  justify-content: flex-start;
}

/* two items */
.print-incentive-container:has(> :nth-child(2):last-child) {
  width: 50%;
}

/* three items */
.print-incentive-container:has(> :nth-child(3):last-child) {
  width: 75%;
}

/* four items */
.print-incentive-container:has(> :nth-child(4):last-child) {
  width: 100%;
  justify-content: stretch;
}

@media print {
  .print-practice-container,
  .msu_footer {
    page-break-inside: avoid;
  }

  .print-practice-container,
  .print-filter-results {
    width: 7.5in;
  }

  .print-practice-container {
    background-color: aliceblue;
    margin-top: 30px;
    box-shadow: none;
    position: relative;
  }

  .print-button,
  .close-button {
    display: none;
  }

  .filter-tag {
    border-radius: 20px;
    background-color: white;
    color: black;
    padding: 1px 12px 3px;
    font-weight: 500;
  }

  .print-most-common {
    background-color: lightgray;
    color: #307d00;
  }

  .print-practice-match {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 22px;
    font-weight: bold;
  }
  @page {
    size: A4;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
