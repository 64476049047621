.eligibility-wrapper {
  position: relative;
}

.spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}

.sidebar-wrapper {
  position: relative;
  left: 0vw;
  height: 90vh;
  width: 400px;
  background-color: lightgray;
}

.map-wrapper {
  position: absolute;
  left: 400px;
  bottom: 0vh;
  height: 90vh;
  width: calc(100% - 400px);
}

.sidebar {
  padding: 20px;
}

.sidebar-section-break {
  position: relative;
  left: 48%;
}

.sidebar-section-1 {
  padding: 15px 10px;
  margin: 10px 0px;
  opacity: 1;
  background-size: 15%;
  background-blend-mode: lighten;
  border: 1px solid transparent;
  border-radius: 10px;
}

.sidebar-section-2 {
  padding: 15px 10px;
  margin: 10px 0px;
  opacity: 1;
  background-size: 15%;
  background-blend-mode: lighten;
  border: 1px solid transparent;
  border-radius: 10px;
}

.sidebar-select {
  width: 155px;
  margin: 5px;
}

.address-input {
  width: 220px;
  margin: 5px;
}

.button-container {
  display: flex;
  width: 350px;
  margin: 10px 0px;
  justify-content: space-between;
}

.button-container-end {
  display: flex;
  width: 330px;
  justify-content: end;
}

.rectangular-button {
  height: 40px;
  width: 330px;
  margin: 5px;
}

.square-button {
  height: 150px;
  width: 150px;
  margin: 1vh;
  font-size: 1em;
}

.redirect-button {
  margin: 2vh 0vw;
}

.back-button,
.submit-button {
  margin: 5px;
}

.button-icon {
  vertical-align: middle;
}

.instruction-container {
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid maroon;
  border-radius: 10px;
}

.instruction-paragraph {
  font-size: 0.9em;
}

.instruction-video-button {
  width: 100%;
}

.instruction-video {
  margin: 40px 30px 10px 50px;
}

.layer-select {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
}

.layer-options {
  position: absolute;
  top: 10px;
  right: 40px;
  width: 200px;
  height: 350px;
  padding: 0px 10px;
  background-color: lightgray !important;
  border: 1px solid black;
  border-radius: 10px;
}

.layer-container {
  padding: 2px;
}

.layer-container-selected {
  padding: 2px;
  border: 2px solid maroon;
  border-radius: 5px;
}

.layer-button {
  width: 50px;
  height: 50px;
  padding: 0px;
  background-color: gray !important;
}

.layer-thumbnail {
  vertical-align: middle;
}

.layer-label {
  margin: 0px 10px;
  vertical-align: middle;
}

.map-legend {
  position: absolute;
  bottom: 20px;
  left: 40px;
  width: 280px;
  padding: 10px;
  background-color: lightgray !important;
  border: 1px solid black;
  border-radius: 10px;
}

.legend-title {
  display: inline-block;
  margin-left: 32px;
  vertical-align: top;
}

.legend-label {
  display: inline-block;
  vertical-align: top;
}

.legend-counties {
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  border: 2px solid maroon;
}

.legend-floodplain {
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(0, 0, 255, 0.3);
  border: 2px solid black;
}

.legend-water {
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(64, 224, 208, 0.3);
  border: 2px solid black;
}

.legend-IL_PH {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 0, 0, 0.3);
  border: 2px solid black;
}

.legend-IL_QI {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 165, 0, 0.3);
  border: 2px solid black;
}

.legend-IN_IB {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(128, 0, 128, 0.3);
  border: 2px solid black;
}

.legend-IN_HS {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 105, 180, 0.3);
  border: 2px solid black;
}

.legend-IN_NB {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 165, 0, 0.3);
  border: 2px solid black;
}

.legend-IN_RP {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 0, 0, 0.3);
  border: 2px solid black;
}

.legend-IA_ES {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 0, 255, 0.3);
  border: 2px solid black;
}

.legend-IA_GG {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(210, 105, 30, 0.3);
  border: 2px solid black;
}

.legend-IA_GR {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(0, 0, 128, 0.3);
  border: 2px solid black;
}

.legend-IA_PR {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 0, 0, 0.3);
  border: 2px solid black;
}

.legend-IA_QH {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 165, 0, 0.3);
  border: 2px solid black;
}

.legend-MS_BB {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(150, 75, 0, 0.3);
  border: 2px solid black;
}

.legend-MS_BP {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(128, 128, 128, 0.3);
  border: 2px solid black;
}

.legend-MS_BQ {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 165, 0, 0.3);
  border: 2px solid black;
}

.legend-MO_QM {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 165, 0, 0.3);
  border: 2px solid black;
}

.legend-MO_SE {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(255, 255, 0, 0.3);
  border: 2px solid black;
}

.legend-OH_MH {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(128, 128 , 0, 0.3);
  border: 2px solid black;
}

.legend-OH_UH {
  display: inline-block;
  width: 20px;
  height: 12px;
  margin: 2px 5px;
  background-color: rgba(220, 20, 60, 0.3);
  border: 2px solid black;
}

.mapboxgl-ctrl-top-right {
  position: absolute !important;
  top: 40px !important;
  right: 10px !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 0px !important;
}

.mapbox-gl-draw_polygon {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.mapbox-gl-draw_polygon:hover {
  background-color: gray !important;
}

.mapbox-gl-draw_trash {
  position: absolute !important;
  top: 29px !important;
  right: 0px !important;
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  background-color: white !important;
}

.mapbox-gl-draw_trash:hover {
  background-color: gray !important;
}

.mapboxgl-children {
  display: none !important;
  height: 0% !important;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

.nongeocheck-form {
  display: flex;
  gap: 10px;
}

#nonGeoCheckForm .nongeocheck-form {
  flex-wrap: wrap;
}

.nongeocheck-button-container {
  display: flex;
  width: 100%;
  margin: 10px 0px;
  justify-content: space-between;
}

.nongeocheck-button {
  color: white;
  background-color: maroon;
}

.nongeocheck-popup {
  margin: 50px 50px 0 50px;
}

.nongeocheck-popup h2 {
  margin-top: -10px;
  margin-bottom: 40px;
  font-size: 24px;
}

.nongeocheck-submit {
  height: 40px;
  color: white;
  background-color: maroon;
  width: 30%;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
}

.nongeocheck-submit:hover {
  cursor: pointer;
}

.non-geocheckbox {
  width: 45%;
  margin-bottom: 0;
}

.nongeo-checkbox-container {
  width: 100%;
}

.eligibility-description {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  border-radius: 10px;
  width: calc(90% + 10px);
  min-height: 120px;
  padding: 10px;
  justify-content: space-around;
}

.description-btn-cont {
  display: flex;
  justify-content: space-around;
}

.eligibility-map {
  width: "calc(100vw - 400px)";
  height: "90vh";
}

.zoom-mobile {
  display: none;
}

.regular-button {
  width: 140px;
  background-color: maroon;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 0;
  margin-bottom: 0;
}

.bandaid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bandaid-inner {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  padding: 0 10px;
}

.filter-modal .ant-modal-content .ant-modal-body {
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-color: #5d1625 #fff;
}

ul.nongeo {
  margin-top: 0;
  padding-top: 0;
  font-size: 16px;
}

h2.nongeo {
  margin-bottom: 0;
  padding-bottom: 20px;
  font-size: 22px;
}

label.nongeo-disclaimer {
  font-size: 20px;
}

label.nongeo-disclaimer span {
  align-self: flex-start;
}

.regular-button.nongeo {
  float: right;
}

.nongeo-disclaimer.ant-checkbox-wrapper-checked {
  color: black;
  background-color: white;
}

.nongeo-disclaimer.ant-checkbox-checked .ant-checkbox-inner {
  background-color: maroon;
  border-color: maroon;
}

label.nongeo-disclaimer span.ant-checkbox {
  padding-top: 10px;
}

@media (max-width: 1100px) {
}

@media (max-width: 700px) {
  .zoom-mobile-wrap {
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(93, 93, 93, 0.49);
    z-index: 1;
  }

  .mobile-section-1 {
    padding: 10px;
    background-color: rgb(187, 187, 187);
    border-radius: 10px;
    margin: 10px 0;
  }

  .mobile-zoom-1,
  .mobile-zoom-2 {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mobile-zoom-2 {
    bottom: 0;
    transform: translateY(80%);
    height: 250px;
  }

  .mobile-field-btn,
  .mobile-proceed-btn {
    height: 80px;
    width: auto;
  }

  .mobile-proceed-btn {
    display: flex;
    align-items: center;
  }

  .mobile-zoom-select {
    width: 30%;
    margin: 0 5px;
  }

  .pa {
    display: none;
  }

  .nongeo-label {
    margin-top: 10px;
  }

  .nongeocheck-popup {
    margin: 0;
    padding: 8px;
  }
  .eligibility-map {
    width: 100%;
  }

  .map-wrapper {
    width: 100%;
    left: 0;
    top: 0;
  }

  .sidebar-wrapper {
    margin-left: -400px;
  }

  .msu_header {
    padding: 3px;
    display: flex;
    align-items: center;
  }

  .header-tool-name {
    font-size: 4vh;
    margin: 0;
    padding: 0;
    padding-top: 20px;
  }

  .header-logo {
    margin-top: 25px;
  }

  .crpMobileNavIcon,
  .crpMobileNavIcon:hover {
    top: -50px;
    right: 05px;
    position: absolute;
  }

  .crpMobileNavIcon.loggedIn,
  .crpMobileNavIcon.loggedIn:hover {
    right: 90px;
  }
}
