.loginForm {
  margin-top: 2em;
}

.registerLink:hover,
.readTerms:hover {
  text-decoration: underline;
  color: #000;
}

.loginButtons {
  margin-bottom: 0;
}
.loginButtons .ant-form-item-control {
  margin-left: auto;
  text-align: center;
}

@media (max-width: 700px) {
  body,
  html {
    overflow-x: hidden;
  }

  .ant-row:has(#loginform) {
    height: 62vh;
  }

  .ant-row .ant-col:has(#loginform) {
    width: 90vw;
    margin-inline-start: initial;
    max-width: none;
    flex: initial;
    margin: 0 auto;
  }
}
