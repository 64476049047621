.msu_header {
  position: relative;
  border-bottom: 4px solid #5d1725;
  background-color: #5d1725;
  /* background-image: url(../../images/header/bg_header_maroon.png); */
  background-position: center top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 1em;
}

@media all and (min-width: 992px) {
  .navbar-nav .nav-item .dropdown-menu {
    display: none;
  }

  /*.navbar-nav .nav-item:hover .nav-link{   }*/
  .navbar-nav .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar-nav .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.centerStyle {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  font-weight: 200px;
}

.header-nav {
  background-color: gray;
}

.crpmainnav {
  background-color: gray;
  display: inline-block;
}

.crpmainnav .ant-menu-submenu,
.crpmainnav .ant-menu-item {
  text-align: center;
  vertical-align: middle;
  min-width: 100px;
  color: white;
}

.crpmainnav .ant-menu-item-active {
  color: white !important;
}

.crpMobileNavIcon {
  display: none;
}

.header-tool-name {
  font-size: 5vh;
  margin: 1vh;
  color: white;
}

.gri-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-logo {
  max-width: 100%;
  height: auto;
  min-width: 300px;
}

/* .header-logo {
  height: 5vh;
  margin-top: 2vh;
} */

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal > .activeMenu::after {
  border-bottom-color: white;
}

.register-login-link a {
  color: white;
}

.register-login-link {
  clear: both;
  float: right;
  margin: 0;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.register-login-link a:hover {
  text-decoration: underline;
  color: #5d1725;
}

.loggedInMenu {
  margin-top: -40px;
  justify-content: end;
  padding-bottom: 9px;
}

@media (min-width: 1101px) and (max-width: 1200px) {
  .ant-row .ant-col:has(.ant-menu) {
    max-width: initial;
    /* margin-inline-start: initial; */
    flex: initial;
  }
}

@media (max-width: 1100px) {
  .header-logo {
    width: auto;
    float: left;
  }

  .header-tool-name-mobile {
    display: none;
  }

  .crpMobileNavIcon,
  .crpMobileNavIcon:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-left: 90%;
    z-index: 1;
    font-size: 1.9rem;
    font-weight: bold;
    color: white;
    background-color: rgb(93, 23, 37);
    border: none;
    border-radius: 6px;
    height: 50px;
    width: 50px;
    top: 35px;
    right: 10px;
  }

  .crpMobileNavIcon,
  .crpMobileNavIcon:hover {
    top: -75px;
    right: 05px;
    position: absolute;
  }

  .crpMobileNavIcon.loggedIn,
  .crpMobileNavIcon.loggedIn:hover {
    right: 90px;
  }

  .crpmainnav {
    display: none;
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }

  .gri-logo {
    display: none;
  }

  .register-login-link {
    margin: 0.5rem;
  }
}

@media (max-width: 700px) {
  .header-logo {
    width: 85%;
    float: left;
  }

  .header-tool-name-mobile {
    display: none;
  }

  .crpMobileNavIcon,
  .crpMobileNavIcon:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin-left: 90%;
    z-index: 1;
    font-size: 1.9rem;
    font-weight: bold;
    color: white;
    background-color: rgb(93, 23, 37);
    border: none;
    border-radius: 6px;
    height: 50px;
    width: 50px;
    top: 35px;
    right: 10px;
  }

  .crpMobileNavIcon,
  .crpMobileNavIcon:hover {
    top: -50px;
    right: 05px;
    position: absolute;
  }

  .crpMobileNavIcon.loggedIn,
  .crpMobileNavIcon.loggedIn:hover {
    right: 90px;
  }

  .crpmainnav {
    display: none;
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }

  .gri-logo {
    display: none;
  }

  .register-login-link {
    margin: 0.5rem;
  }
}

#branding-footer {
  display: none !important;
}
